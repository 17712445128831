import React, {Fragment} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Image from '@components/Image';
import HeroSectionContainer, {HeroSectionProps} from './HeroSectionContainer';
import {AmbientVideo} from '@components/Video';
import {styled} from '@styles';
import {HeroContentInner, HeroContentOuter} from './';

export const StandaloneImageContainer = styled.figure.attrs(() => ({
  ['aria-hidden']: true,
}))`
  width: 100%;
  max-width: 100%;
  margin: 0;
`;

const HERO_IMAGE_QUERY = graphql`
  query HeroQueryWithCreative(
    $quality: Int
  ) {
    homepageHero: file(relativePath: {eq: "HomepageHero.jpg"}) {
      ...fluidImageFragment
    }
    careersHero: file(relativePath: {eq: "CareersHero.jpg"}) {
      ...fluidImageFragment
    }
    subpageHero: file(relativePath: {eq: "SubpageHero.jpg"}) {
      ...fluidImageFragment
    }
  }
`;

/** type will be expanded when the props have been supplied from gq query */
type HeroSectionWithCreativeProps = HeroSectionProps & {
  creativeType?: 'image' | 'video';
};

export default function HeroWithStandaloneCreative({
  heroSectionType = 'tall',
  creativeType = 'video',
  altHero = false,
  children,
}: HeroSectionWithCreativeProps): JSX.Element {
  const data = useStaticQuery(HERO_IMAGE_QUERY);
  const {fluid: homepageHero} = data.homepageHero.childImageSharp;
  const {fluid: subpageHero} = data.subpageHero.childImageSharp;
  const {fluid: careersHero} = data.careersHero.childImageSharp;

  const renderCreative = (type: string): JSX.Element | null => {
    switch (type) {
      case 'video':
        return <AmbientVideo />;
      case 'image':
        return (
          <StandaloneImageContainer>
            <Image
              childImageSharp={careersHero}
              preservePresentationWidth={false}
              style={{
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                margin: '0',
              }}
            />
          </StandaloneImageContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <HeroSectionContainer
        heroSectionType={heroSectionType}
        background={altHero ? subpageHero.src : homepageHero.src}
      >
        <HeroContentOuter>
          <HeroContentInner>{children}</HeroContentInner>
        </HeroContentOuter>
      </HeroSectionContainer>
      {renderCreative(creativeType)}
    </Fragment>
  );
}
