import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import RichTextCallout from '@components/SectionCallout/RichTextCallout';
import {styled, theme, ThemeProvider} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import StaticStairGrid from '@components/layout/StaticStairGrid';
import Image from '@components/Image';
import GuidingPrinciple from '@components/GuidingPrinciples/GuidingPrinciple';
import {getSrc} from 'gatsby-plugin-image';

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'ccintroContainer'
})`
  padding: 6em 2em;
`;

const Content = styled.div.withConfig({
  componentId: 'cccIntroContent'
})`
  max-width: 603px;
  width: 100%;
`;

const ContentContainer = styled.div.withConfig({
  componentId: 'cccIntroContentContainer'
})`
  margin: 0 auto;
  max-width: 100%;
  .wide & {
    max-width: ${theme.maxWidth};
  }
`;

const ImageContainer = styled.div.withConfig({
  componentId: 'cccIntroImageContainer'
})`
  width: 100%;
  margin: 4em auto;
  max-width: 100%;
  .wide & {
    margin: 4em auto 10em;
    max-width: 90%;
  }
`;

//new ccc image
const CHART_QUERY = graphql`
  query ChartImage($quality: Int) {
    chartImage: file(relativePath: {eq: "new-ccc.jpg"}) {
      ...fluidImageFragment
    }
  }
`;

const GRID_DATA = [
  {
    title: `Courses`,
    description: `Challenging, free or paid online learning courses designed for self-paced learning.`,
  },
  {
    title: `Professional Certificates`,
    description: `Intensive, focused opportunities for upskilling mid-career professionals.`,
  },
  {
    title: `Professional & Executive Education`,
    description: `Structured certificate courses earned to show core competencies.`,
  },
  {
    title: `Boot Camps`,
    description: `Technical reskilling in data analytics, UX/UI, cybersecurity, and more.`,
  },
  {
    title: `MicroBachelors®`,
    description: `Undergraduate-level micro credential for career advancement or a degree path.`,
  },
  {
    title: `MicroMasters®`,
    description: `Graduate-level micro credential for career advancement or a degree path.`,
  },
  {
    title: `Undergraduate Degrees`,
    description: `Online degree programs with top universities.`,
  },
  {
    title: `Graduate Degrees`,
    description: `Professional degree programs that build subject matter expertise.`,
  },
];

const CCCIntro = (props): JSX.Element => {
  const data = useStaticQuery(CHART_QUERY);
  
  return (
    <ThemeProvider
      theme={{
        maxWidth: '80%',
      }}
    >
      <Container>
        <ContentContainer>
          <Content>
            <ThemeProvider theme={{maxWidth: '100%'}}>
              <RichTextCallout {...props} />
            </ThemeProvider>
          </Content>
        </ContentContainer>
        <ImageContainer>
          <Image childImageSharp={data.chartImage} alt="" />
        </ImageContainer>
        <ContentContainer>
          <StaticStairGrid
            numberOfRows={3}
            gridItems={GRID_DATA}
            GridItemComponent={GuidingPrinciple}
          />
        </ContentContainer>
      </Container>
    </ThemeProvider>
  );
};

export default CCCIntro;
