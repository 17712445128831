import React from 'react';
import {StandaloneImageContainer} from '@components/Hero/HeroSectionWithCreative';
import Image from '@components/Image';
import {Layers} from '@styles';

const FullWidthImageSection = props => {
  return (
    <StandaloneImageContainer>
      <Image
        childImageSharp={props.image.childImageSharp}
        alt={props.caption}
        preservePresentationWidth={false}
        style={Object.assign(
          {
            height: '100%',
            width: '100%',
            maxWidth: '100%',
            margin: '0',
            maxHeight: '645px',
            zIndex: Layers.Background,
          },
          props.style,
        )}
        imgStyle={props.imgStyle}
      />
    </StandaloneImageContainer>
  );
};

export default FullWidthImageSection;
